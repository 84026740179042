<template>
    <div class="main-page-size tw-mt-7">
        <BreadCrumbs :crumbs="this.breadCumbs" class="tw-pb-5" />
        <v-row class="tw-mt-5">
            <v-col cols="12" md="7">
                <div
                    class="tw-flex tw-items-center tw-flex-row  tw-justify-start tw-pb-10"
                >
                    <Title
                        class="tw-pr-2 tw-text-lg xl:tw-text-3xl"
                        :name="'Dein Warenkorb'"
                        :size="'20'"
                    />
                    <div class="tw-font-light">(2 Artikel)</div>
                </div>
                <IconLabel
                    className="tw-flex tw-items-center tw-font-light"
                    height="40"
                    :color="themePrimary"
                    text
                    dense
                    type="info"
                >
                    <template v-slot:content>
                        <span
                            >Artikel im Warenkorb werden nicht reserviert</span
                        >
                    </template>
                </IconLabel>
                <ArticleOverview
                    v-for="(article, key) in articles"
                    :key="key"
                    :article="article"
                    basket
                />
                <IconLabel
                    className="tw-flex tw-items-center tw-font-light tw-mt-5"
                    height="40"
                    :color="themePrimary"
                    text
                    dense
                    type="info"
                >
                    <template v-slot:content>
                        <span>Innerhalb von <span class="tw-font-medium">3 Stunden 5 Minuten</span> bestellen, Versand erfolgt <span class="tw-font-bold">heute</span></span
                        >
                    </template>
                </IconLabel>
            </v-col>
            <v-col cols="12" md="4" offset-md="1">
                <DeliveryOverview basket />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ArticleOverview from '@/components/content/items/article/ArticleOverview';
import DeliveryOverview from '../checkout/delivery/DeliveryOverview';
import Title from '../../components/shared/typography/Title';
import BreadCrumbs from '../../components/content/layout/BreadCrumbs';
import IconLabel from '../../components/shared/labels/icon-label/IconLabel';
import { articles } from '@/services/data-mock/articles';
export default {
    name: 'Basket',
    components: {
        ArticleOverview,
        DeliveryOverview,
        BreadCrumbs,
        IconLabel,
        Title,
    },
    data() {
        return {
            breadCumbs: [
                {
                    label: 'Starseite',
                    link: '/kategorie',
                },
                {
                    label: 'Warenkorb',
                    link: '/warenkorb',
                },
            ],
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            articles,
        };
    },
};
</script>
